import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private http = inject(HttpClient);

  getVersion(): Observable<Version> {
    return this.http.get<Version>('../../../version.json').pipe(
      catchError(() => {
        return of({ name: 'Error', version: '0.0.0', publishDate: '0000-00-00' } as Version);
      })
    );
  }
}

interface Version {
  name: string;
  version: string;
  publishDate: string;
}
