import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private readonly URL_API = environment.URL_API;
  private http = inject(HttpClient)

  getRolesAndPermissions(): Observable<any> {
    return this.http.get(`${this.URL_API}user/rolesAndPermissions`);
  }

  getRoles(): Observable<any> {
    return this.http.get(`${this.URL_API}role`);
  }
}
