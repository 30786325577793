<ng-container *ngIf="!loading">
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="grid justify-content-center text-center">
        <div class="col-12 md:col-6">
          <p class="mb-2">Precio de Costo:</p>
          <h2 class="mt-1">{{product().netPrice | currency}}</h2>
        </div>
        <div class="col-12 md:col-6">
          <p class="mb-2">Precio de Costo con IVA:</p>
          <h2 class="mt-1">{{product().priceWithVAT | currency}}</h2>
        </div>
        <div class="col-12 md:col-6">
          <p class="mb-2">Precio de Venta:</p>
          <h2 *ngIf="product().salePriceWithoutVAT" class="mt-1">{{product().salePriceWithoutVAT | currency}}</h2>
          <h2 *ngIf="!product().salePriceWithoutVAT" class="mt-1">-</h2>
        </div>
        <div class="col-12 md:col-6">
          <p class="mb-2">Precio de Venta con IVA:</p>
          <h2 *ngIf="product().salePriceWithVAT" class="mt-1">{{product().salePriceWithVAT | currency}}</h2>
          <h2 *ngIf="!product().salePriceWithVAT" class="mt-1">-</h2>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-4 xl:col-3">
          <h6 class="mb-1">Tipo de Producto:</h6>
          <p>{{product().type?.name ?? '-'}}</p>
        </div>
        <div class="col-12 md:col-6 lg:col-4 xl:col-3">
          <h6 class="mb-1">Tipo de Unidad:</h6>
          <p>{{product().unitType?.name ?? '-'}}</p>
        </div>
        <div class="col-12 md:col-6 lg:col-4 xl:col-3">
          <h6 class="mb-1">Marca:</h6>
          <p>{{product().brand?.name ?? '-'}}</p>
        </div>
        <div class="col-12 md:col-6 lg:col-4 xl:col-3">
          <h6 class="mb-1">Modelo:</h6>
          <p>{{product().model?.name ?? '-'}}</p>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-4 xl:col-4">
          <h6 class="mb-1">Porcentaje de Venta:</h6>
          <p>{{product().salesPercentage| percent}}</p>
        </div>
        <div class="col-12 md:col-6 lg:col-4 xl:col-2">
          <h6 class="mb-1">IVA:</h6>
          <p>{{product().vat | percent}}</p>
        </div>
        <div *ngIf="product().dueDate" class="col-12 md:col-6 lg:col-4 xl:col-3">
          <h6 class="mb-1">Vence el:</h6>
          <p>{{product().dueDate | date:'dd/MM/yyyy'}}</p>
        </div>
        <div class="col-12 md:col-6 lg:col-4 xl:col-3">
            <h6 class="mb-1">Creado el:</h6>
            <p>{{product().createdAt | date:'dd/MM/yyyy'}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="grid">

    <div *ngFor="let item of product().branches" class="col-12 md:col-6 lg:col-4">
      <p-card>
        <h5>Sucursal: {{item.branch.name}}</h5>
        <p-divider></p-divider>
        <div class="col-12 md:col-6">
          <h6 class="mb-1">Cantidad:</h6>
          <p>{{item.amount}}</p>
        </div>
        <div class="col-12 md:col-6">
          <h6 class="mb-1">Precio total:</h6>
          <p>{{product().netPrice * item.amount | currency}}</p>
        </div>
        <div class="col-12 md:col-6">
          <h6 class="mb-1">Precio total con IVA:</h6>
          <p>{{product().priceWithVAT * item.amount | currency}}</p>
        </div>
      </p-card>

   </div>
  </div>
  <div class="grid">
    <div class="col-12">
      <h6 class="mb-1">Descripción:</h6>
      <p>{{product().description}}</p>
    </div>
  </div>
  <div *ngIf="product().files" class="grid justify-content-center text-center">
    <div class="col-fixed">
      <p-galleria [value]="images" [responsiveOptions]="responsiveOptions" [containerStyle]="{ 'max-width': '640px' }" [numVisible]="5">
        <ng-template pTemplate="item" let-item>
            <img [src]="item" style="height: 400px;" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
                <img [src]="item" style="height: 50px;" />
            </div>
        </ng-template>
    </p-galleria>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="grid">
    <div class="col-12">
      <h6 class="mb-1">Caracteristicas:</h6>
      <p-table [value]="product().characteristics">
        <ng-template pTemplate="header">
          <tr>
            <th>Nombre</th>
            <th>Valor</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-characteristic>
          <tr>
            <td>{{ characteristic.name }}</td>
            <td>{{ characteristic.value }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">No se agregaron caracteristicas</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</ng-container>
<stock-loading *ngIf="loading"/>
