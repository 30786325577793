import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { StorageService } from './shared/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { datadogLogs } from '@datadog/browser-logs'
import { ColorScheme, LayoutService } from './layout/service/app.layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private primengConfig: PrimeNGConfig, 
              private storageService: StorageService, 
              private translateService: TranslateService,
              private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    if (!this.storageService.existsCookie()) {
      this.storageService.setCookie();
    }
    if (!this.storageService.existsLanguage()) {
      this.storageService.setLanguage('es');
    }
    this.translateService.setDefaultLang(this.storageService.getLanguage());
    this.translateService.stream('primeng').subscribe(data => {
      this.primengConfig.setTranslation(data);
    });

    if (this.layoutService.isMobile()) {
      this.storageService.setViewMode(true)      
    }

    // datadogLogs.init({
    //   clientToken: 'pubb5613035f6fa2f3808e459f3543bb07f',
    //   site: 'datadoghq.com',
    //   forwardErrorsToLogs: true,
    //   sessionSampleRate: 100,
    // })
  }

  


}
