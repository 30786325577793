import { HttpClient, HttpParameterCodec, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserRequest } from '../../models/requests/user.request';
import { environment } from "../../../../environments/environment";

@Injectable()
export class UsersService {
  private readonly URL_API = environment.URL_API;
  private http = inject(HttpClient)
  public encoder: HttpParameterCodec;


  addUser(user: UserRequest) {
    return this.http.post(`${this.URL_API}user`, user);
  }

  updateUser(user: UserRequest, id: number) {
    return this.http.put(`${this.URL_API}user/${id}`, user);
  }

  getUser(id: number) {
    return this.http.get(`${this.URL_API}user/${id}`);
  }

  deleteUser(id: number) {
    return this.http.delete(`${this.URL_API}user/${id}`);
  }

  updatePicture(picture: File) {
    const formData = new FormData();
    formData.append('file', picture);
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.put(`${this.URL_API}user/updatePicture`, formData, { headers: headers });
  }


  getUsers(query?: string,
    roleId?: number,
    email?: string,
    username?: string,
    identificationNo?: string,
    countryId?: number,
    stateId?: number,
    cityId?: number,
    branchId?: number,
    page?: number,
    pageSize?: number,
    order?: string) {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });

    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>query, 'query');
    }
    if (roleId !== undefined && roleId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>roleId, 'roleId');
    }
    if (email !== undefined && email !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>email, 'email');
    }
    if (username !== undefined && username !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>username, 'username');
    }
    if (identificationNo !== undefined && identificationNo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>identificationNo, 'identificationNo');
    }
    if (countryId !== undefined && countryId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>countryId, 'countryId');
    }
    if (branchId !== undefined && branchId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>branchId, 'branchId');
    }
    if (stateId !== undefined && stateId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>stateId, 'stateId');
    }
    if (cityId !== undefined && cityId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>cityId, 'cityId');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>page, 'Page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }
    if (order !== undefined && order !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>order, 'Order');
    }
    return this.http.get(`${this.URL_API}user/all`, { params: localVarQueryParameters });
  }


  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
}
