import { StorageService } from 'src/app/shared/services/storage.service';
import { Component, ElementRef, OnInit, ViewChild, WritableSignal, inject, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { TokenModel } from '../shared/models/token.model';
import { UserResponse } from '../main/models/responses/user.response';
import { UsersService } from '../main/services/users/users.service';
import { ProductsService } from '../main/services/products/products.service';
import { ProductFilter } from '../main/models/filters/product.filter';
import { ProductResponse } from '../main/models/responses/product.response';
import { Response } from '../main/models/responses/response';
import { UserFilter } from '../main/models/filters/user.filter';
import { Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeOut, fadeIn } from 'ng-animate';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
    providers: [
      UsersService,
      ProductsService
    ],
    animations: [
      trigger('showSearch', [
        transition(':leave', useAnimation(fadeOut, {
          params: { timing: 0.2 }
        })),
        transition(':enter', useAnimation(fadeIn, {
          params: { timing: 0.2 }
        }))
      ])
    ]
})
export class AppTopBarComponent implements OnInit {

    menu: MenuItem[] = [];
    searchReponse: WritableSignal<SearchResponse[]> = signal(null);
    loading: boolean = false;

    @ViewChild('searchinput') searchInput!: ElementRef;

    @ViewChild('menubutton') menuButton!: ElementRef;
    private userService = inject(UsersService);
    private productsService = inject(ProductsService);
    private router = inject(Router);
    urlAvatar: string;
    query: string = '';
    searchInit: boolean = false;
    showSearch: boolean = true;

    searchActive: boolean = false;
    constructor(public layoutService: LayoutService, private storageService: StorageService) {

    }
    ngOnInit(): void {
      const permissions = this.storageService.getPermissions();
      if (permissions) {
        if (permissions.includes('Acceso Total') || permissions.includes('Ver Productos')) {
          this.showSearch = true;
        } else {
          this.showSearch = false;
        }
      } else {
        this.showSearch = false;
      }

      this.getById(Number.parseInt(this.storageService.getDataUser().id));
    }

    getById(id: number) {
      this.userService.getUser(id).subscribe({
        next: (data: UserResponse) => {
          this.urlAvatar = data.pictureUrl;
        },
        error: (error) => {
        },
        complete: () => {}
      });
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    search() {
      if (this.query.length >= 3) {
        this.searchInit = true;
        this.loading = true;
        this.getProducts({query: this.query, page: 1, pageSize: 5, order: 'name asc'});
      } else {
        this.searchInit = false;
        this.searchReponse.set(null);
      }
    }

    goToPage(item: SearchResponse) {
      if (item.type == 'product') {
        this.router.navigateByUrl(`s/products?barCode=${item.code}`);
      } else if (item.type == 'user') {
        this.router.navigateByUrl(`s/users?identificationNo=${item.code}`);
      }
      this.searchInit = false;
      this.searchReponse.set(null);
      this.query = '';
      this.deactivateSearch();
    }


    getProducts(filter: ProductFilter) {
      this.productsService.getProducts(
        filter.query,
        filter.name,
        filter.barCode,
        filter.amount,
        filter.brandId,
        filter.modelId,
        filter.typeId,
        filter.unitTypeId,
        filter.branchId,
        filter.dateFrom,
        filter.dateTo,
        filter.dueDate,
        filter.isExpired,
        filter.page,
        filter.pageSize,
        filter.order).subscribe({
          next: (data: Response<ProductResponse>) => {
            if (data.count > 0) {
              this.searchReponse.set(data.data.map(x => {
                return {
                  id: x.id,
                  name: x.name,
                  type: 'product',
                  code: x.barCode
                }
              }));
            }
          },
          error: (error) => {3
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          }
        });
    }

    deactivateSearch() {
      if (this.query.length == 0) {
        this.searchActive = false;
        this.loading = false;
      }
    }

    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    logout() {
      this.storageService.logout();
    }

    get layoutTheme(): string {
        return this.layoutService.config.layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }

    get logo(): string {
        const path = 'assets/images/logo.png';
        return path;
    }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }

    get userLogin(): TokenModel {
        return this.storageService.getDataUser();
    }

    get enterprise(): string {
        return localStorage.getItem('enterprise') || '';
    }
}

interface SearchResponse {
  id: number;
  name: string;
  type: string;
  code: string;
}
