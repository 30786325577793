import {Component} from '@angular/core';
import {LayoutService} from "./service/app.layout.service";
import { StorageService } from '../shared/services/storage.service';
import { VersionService } from '../shared/services/version.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

    uuid: string;
    name: string;
    version: string;
    date: Date;
    constructor(public layoutService: LayoutService, private storageService: StorageService, private versionService: VersionService) {
      this.uuid = `UUID: ${this.storageService.getCookie().toUpperCase()}`;
      this.versionService.getVersion().subscribe({
        next: (data) => {
          if (data) {
            this.name = `${data.name}`;
            this.version = `${data.version}`;
            this.date = new Date(data.publishDate);
          }
        },
        error: (error) => { },
        complete: () => { }
      });
    }

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }
}
