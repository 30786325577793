import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { StorageService } from './storage.service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly URL_API = environment.URL_API;
  private http = inject(HttpClient)
  private store = inject(StorageService)

  refreshToken() {
    return this.http.get(`${this.URL_API}auth/refreshToken?userId=${this.store.getUserId()}&refreshToken=${this.store.getRefreshToken()}&cookie=${this.store.getCookie()}`);
  }
}
