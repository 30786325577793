<div class="layout-topbar">
  <a class="app-logo" routerLink="/s">
    <img alt="app logo" [src]="logo">
    <span class="app-name">DAStock</span>
  </a>

  <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
    <span></span>
  </button>

  <ul class="topbar-menu">
    <li *ngFor="let item of tabs; let i = index">
      <a [routerLink]="item.routerLink" routerLinkActive="active-route"
        [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
        [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment!" [skipLocationChange]="item.skipLocationChange!"
        [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
        <span>{{item.label}}</span>
      </a>
      <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
    </li>
    <li *ngIf="!tabs || tabs.length === 0" class="topbar-menu-empty">
      Use (CTRL + Click) para abrir una pestaña en el sistema
    </li>
  </ul>

  <div class="topbar-search " [ngClass]="{'topbar-search-active': searchActive}">
    <button class="topbar-searchbutton p-link hidden-search" *ngIf="showSearch" type="button" (click)="activateSearch()">
      <i class="fa-duotone fa-magnifying-glass"></i>
    </button>

    <div class="search-input-wrapper">
      <span class="p-input-icon-right">
        <input #searchinput type="text" [(ngModel)]="query" (ngModelChange)="search()" pInputText placeholder="Buscar"
          (blur)="deactivateSearch()" (keydown.escape)="deactivateSearch()" />
        <i class="fa-duotone fa-magnifying-glass"></i>
      </span>
    </div>
  </div>
  <div *ngIf="searchInit" [@showSearch] class="search-content pt-3">
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="searchReponse()">
        <ng-container *ngFor="let item of searchReponse()">
          <div (click)="goToPage(item)" class="flex justify-content-center animate__animated animate__fadeIn animate__fast cursor-pointer">
            <div class="col-3 pl-4 align-self-center align-content-center align-items-center">
              <p-tag *ngIf="item.type == 'product'">
                <div class="p-1">
                  <p>Producto</p>
                </div>
              </p-tag>
              <p-tag *ngIf="item.type == 'user'">
                <div class="p-1">
                  <p>Usuario</p>
                </div>
              </p-tag>
            </div>
            <div class="col-9 align-self-center align-content-center align-items-center">
              <h5 class="mb-2">{{item.name}}</h5>
              <p>{{item.code}}</p>
            </div>
          </div>
          <div class="flex justify-content-center  animate__animated animate__fadeIn animate__fast">
            <div class="col-11 p-0">
              <p-divider></p-divider>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!searchReponse()">
        <div class="flex justify-content-center text-center animate__animated animate__fadeIn animate__fast">
          <div class="col-6 pt-7">
            <h5>No se encontraron resultados</h5>
          </div>
        </div>
      </ng-container>
    </ng-container>


    <ng-container *ngIf="loading">
      <div class="flex justify-content-center animate__animated animate__fadeIn animate__faster">
        <div class="col-11 p-0">
          <p-skeleton height="5rem"></p-skeleton>
          <p-divider></p-divider>
          <p-skeleton height="5rem"></p-skeleton>
          <p-divider></p-divider>
          <p-skeleton height="5rem"></p-skeleton>
          <p-divider></p-divider>
          <p-skeleton height="5rem"></p-skeleton>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="topbar-profile">
    <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterClass="hidden"
      enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
      <p-avatar [image]="urlAvatar ? urlAvatar : '../../../../../assets/images/user-default.png'" styleClass="mr-2" size="large" shape="circle"></p-avatar>
      <span class="profile-details">
        <span class="profile-name">{{userLogin.firstName}} {{userLogin.lastName}}</span>
        <span class="profile-job">{{enterprise}}</span>
      </span>
      <i class="pi pi-angle-down"></i>
    </button>
    <ul
      class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top sm:w-12rem mt-2 right-0 top-auto">
      <li>
        <a routerLink="/s/profile" style="color: var(--v-menuitem-text-color);" pRipple
          class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
          <i class="fa-duotone fa-user mr-3"></i>
          <span>{{'system.profile' | translate}}</span>
        </a>
        <a routerLink="/s/settings" style="color: var(--v-menuitem-text-color);" pRipple
          class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
          <i class="fa-duotone fa-gear mr-3"></i>
          <span>{{'system.settings' | translate}}</span>
        </a>
        <a (click)="logout()" style="color: var(--v-menuitem-text-color);" pRipple
          class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
          <i class="fa-duotone fa-right-from-bracket mr-3"></i>
          <span>{{'system.logOut' | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
