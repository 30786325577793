import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private storageService = inject(StorageService);
  private refreshTokenService = inject(AuthService);
  private messageService = inject(MessageService);
  private RefreshToken: string;

  private handleAuthError(err: HttpErrorResponse, request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401) {
      return this.handleRefreshToken(request, next);
    }

    if (err.status !== 401 && !request.url.includes('auth/refreshToken')) {
      // datadogLogs.logger.error(err.status.toString(), err.error);
      this.messageService.add({severity:'error', life: 5000, summary: 'Error', detail: `${err.status} - ${err.error.Message ? err.error.Message : 'Error desconocido'}`});
    }
    return throwError(err);
  }

  private handleRefreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.refreshTokenService.refreshToken().pipe(
      switchMap((data) => {
        this.storageService.setDataLogin(data);
        this.RefreshToken = data['refreshToken'];
        return next.handle(this.addTokenHeader(request));
      }),
      catchError(error => {
        let refresh = this.storageService.getRefreshToken();
        if (refresh === this.RefreshToken) {
          return next.handle(this.addTokenHeader(request));
        } else {
          this.storageService.logoutWithoutService();
          return throwError(error);
        }

      }
    ));
  }

  private addTokenHeader(request: HttpRequest<any>) {
    let header = new HttpHeaders().set('Authorization', `Bearer ${this.storageService.getToken()}`)
    return request.clone({headers: header});
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/auth') ||
        request.url.includes('ipify')) {
      return next.handle(request);
    }
    return next.handle(this.addTokenHeader(request)).pipe(catchError(error => this.handleAuthError(error, request, next)));
  }
}
