import { Component, OnInit, inject, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { EnterpriseService } from './services/enterprise/enterprise.service';
import { RolesService } from './services/roles/roles.service';
import { StorageService } from '../shared/services/storage.service';
import { ColorScheme, LayoutService } from '../layout/service/app.layout.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProductsService } from './services/products/products.service';
import { ProductFilter } from './models/filters/product.filter';
import { ProductResponse } from './models/responses/product.response';
import { ShowProductComponent } from './pages/product/show-product/show-product.component';
import { Response } from './models/responses/response';
import { InputNumber } from 'primeng/inputnumber';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [
    EnterpriseService,
    DialogService,
    ProductsService,
    MessageService
  ],
  host: {
    '(document:keypress)': 'handleKeyboardEvent($event)'
  }
})
export class MainComponent implements OnInit  {

  @ViewChild('input') input: InputNumber;
  private enterpriseService = inject(EnterpriseService);
  private dialogService = inject(DialogService);
  private storageService = inject(StorageService);
  private layoutService = inject(LayoutService);
  private messageService = inject(MessageService);
  barCode: string = '';

  loading: boolean = false;
  private productsService = inject(ProductsService);

  ngOnInit(): void {
    this.setTheme();
    this.enterpriseService.getEnterprise().subscribe({
      next: (data) => {
        localStorage.setItem('enterprise', data['fantasyName']);
      },
      error: (error) => {},
      complete: () => {}
    });

  }

  setTheme() {
    let theme = this.storageService.getTheme();
    let colorScheme = this.storageService.getLayoutTheme();
    if (colorScheme) {
      this.changeTheme(colorScheme);
    } else {
      this.changeTheme('indigo');
    }

  }



  changeColorScheme(colorScheme: ColorScheme, theme?: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href');
    const currentColorScheme = 'theme-' + this.layoutService.config.colorScheme;
    const newColorScheme = 'theme-' + colorScheme;
    let newHref = themeLinkHref!.replace(currentColorScheme, newColorScheme);
    if (theme) {
      newHref = newHref.replace('indigo', theme);
    }
    this.replaceThemeLink(newHref, () => {
      this.layoutService.config.colorScheme = colorScheme;
      this.layoutService.onConfigUpdate();
    });
  }

  changeTheme(theme: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);
    this.replaceThemeLink(newHref, () => {
      this.layoutService.config.theme = theme;
      this.layoutService.onConfigUpdate();
    });
  }

  replaceThemeLink(href: string, onComplete: Function) {
    const id = 'theme-link';
    const themeLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete();
    });
  }


  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code.includes('Digit') || event.code.includes('Numpad')) {
      this.barCode += event.key;
    }
    if (event.code === 'Enter' && !event.ctrlKey && !event.altKey) {
      if (this.barCode.length > 5) {
        this.getProducts({barCode: this.barCode});
      }
    }
  }

  getProducts(filter: ProductFilter) {
    this.productsService.getProducts(
      filter.query,
      filter.name,
      filter.barCode,
      filter.amount,
      filter.brandId,
      filter.modelId,
      filter.typeId,
      filter.unitTypeId,
      filter.branchId,
      filter.dateFrom,
      filter.dateTo,
      filter.dueDate,
      filter.isExpired,
      filter.page,
      filter.pageSize,
      filter.order).subscribe({
        next: (data: Response<ProductResponse>) => {
          if (data.data.length !== 0) {
            this.showMore(data.data[0]);
          } else {
            this.messageService.add({severity:'warn', summary:'Producto no encontrado', detail:`Para el código ${this.barCode}`, life: 3000});
          }
        },
        error: (error) => {
        },
        complete: () => {
          this.loading = false;
          this.barCode = '';
        }
      });
  }

  showMore(product: ProductResponse) {
    this.dialogService.open(ShowProductComponent, {
      header: `${product.name} - ${product.barCode}`,
      width: '70%',
      baseZIndex: 10000,
      data: {
        id: product.id,
        branchIds: product.branchIds
      },
      resizable: false,
      draggable: false
    });
  }

}
