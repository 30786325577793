import { Injectable, inject } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from 'ngx-cookie-service';
import { v4 as uuidv4 } from 'uuid';
import { TokenModel } from '../models/token.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { RoleResponse } from 'src/app/main/models/responses/user.response';
import { RolesPermissionsModel } from '../models/roles-permissions.model';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private cookieService = inject(CookieService);
  private readonly URL_API = environment.URL_API;
  private http = inject(HttpClient)
  private router = inject(Router)
  constructor(private jwtHelperService: JwtHelperService) { }

  setDataLogin(data: any) {
    localStorage.setItem('token-stock', data.token);
    localStorage.setItem('refresh-token-stock', data.refreshToken);
  }

  setCookie() {
    this.cookieService.set('cookie-stock', uuidv4(), 365, '/', '', true, 'Strict');
  }

  setLanguage(code: string) {
    localStorage.setItem('language-stock', code);
  }

  getLanguage(): string {
    return localStorage.getItem('language-stock');
  }

  setViewMode(value: boolean) {
    localStorage.setItem('view-mode-stock', `${value}`);
  }

  getViewMode(): boolean {
    return localStorage.getItem('view-mode-stock') === 'true' ? true : false;
  }

  existsLanguage(): boolean {
    return localStorage.getItem('language-stock') ? true : false;
  }

  existsCookie(): boolean {
    return this.cookieService.check('cookie-stock');
  }

  setPermissions(rolesPermissionsModel: RolesPermissionsModel) {
    let permissions: string[] = [];
    rolesPermissionsModel.roles.forEach(role => {
      role.permissions.forEach(permission => {
        permissions.push(permission.name);
      });
    });
    rolesPermissionsModel.permissions.forEach(permission => {
      if (permission.isRevoked) {
        permissions = permissions.filter(p => p != permission.name);
      } else {
        permissions.push(permission.name);
      }
    });
    localStorage.setItem('permissions-stock', JSON.stringify(permissions));
  }

  getPermissions(): string[] {
    return JSON.parse(localStorage.getItem('permissions-stock'));
  }

  clearDataLogin() {
    localStorage.clear();
  }

  hasToken(): boolean {
    return localStorage.getItem('token-stock') ? true : false;
  }

  getCookie() {
    return this.cookieService.get('cookie-stock');
  }

  setToken(token: string) {
    localStorage.setItem('token-stock', token);
  }

  getToken(): string {
    return localStorage.getItem('token-stock');
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refresh-token-stock', token);
  }

  getRefreshToken(): string {
    return localStorage.getItem('refresh-token-stock');
  }

  getUserId(): number {
    const token = this.getDataUser();
    return Number.parseInt(token.id);
  }

  logout() {
    let cookie = this.getCookie();
    let userId = this.getUserId();
    let header = new HttpHeaders()
    .set('Authorization', `Bearer ${this.getToken()}`)
    this.http.get(`${this.URL_API}auth/logout?userId=${userId}&cookie=${cookie}`, {headers: header}).subscribe({
      next: (data) => {},
      error: error => {
        if (error.status == 401) {
          this.http.get(`${this.URL_API}auth/refreshToken?userId=${userId}&refreshToken=${this.getRefreshToken()}&cookie=${cookie}`).subscribe({
            next: (result) => {
              this.setDataLogin(result);
            },
            error: error => {},
            complete: () => {
              this.logout();
            }
          });
        }
      },
      complete: () => {
        localStorage.removeItem('token-stock');
        localStorage.removeItem('refresh-token-stock');

        this.router.navigate(['auth']);
      }
    })
  }

  logoutWithoutService() {
    localStorage.removeItem('token-stock');
    localStorage.removeItem('refresh-token-stock');

    this.router.navigate(['auth']);
  }

  getDataUser() {
    const token = this.getToken();
    if (token) {
      return this.jwtHelperService.decodeToken<TokenModel>(token);
    }
    return null;
  }

  getBrowserInfo(): string {
    let ua = navigator.userAgent, tem,
    M = ua.match(/(opera|chrome|safari|edg|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1] === 'Chrome'){
        tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
        if(tem != null && tem.includes('OPR')) return tem[1].replace('OPR', 'Opera');
        if(tem != null && tem.includes('Edg')) return tem[1].replace('Edg', 'MicrosoftEdge');
    }
    return M[1];
  }


  setTheme(theme: string) {
    localStorage.setItem('theme', theme);
  }

  getTheme(): string {
    return localStorage.getItem('theme');
  }

  setLayoutTheme(theme: string) {
    localStorage.setItem('layout-theme', theme);
  }

  getLayoutTheme(): string {
    return localStorage.getItem('layout-theme');
  }
}
