import { Component, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProductResponse } from 'src/app/main/models/responses/product.response';
import { ProductsService } from 'src/app/main/services/products/products.service';

@Component({
  selector: 'app-show-product',
  templateUrl: './show-product.component.html',
  styleUrls: ['./show-product.component.scss'],
  providers: [
    ProductsService,
    DialogService
  ]
})
export class ShowProductComponent implements OnInit {

  product: WritableSignal<ProductResponse> = signal(null);
  loading: boolean = false;
  responsiveOptions: any[] | undefined;
  images: any[];

  private productsService = inject(ProductsService);
  private ref = inject(DynamicDialogConfig);

  ngOnInit(): void {
    this.getProduct(this.ref.data['id'], this.ref.data['branchIds']);
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 5
      },
      {
          breakpoint: '768px',
          numVisible: 3
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
    ];
  }

  getProduct(id: number, branchIds: number[]) {
    this.loading = true;
    this.productsService.getProduct(id, branchIds).subscribe(
      {
        next: (data: ProductResponse) => {
          this.product.set(data);
          this.images = data.files.map(p => p.fullPath);
        },
        error: (error) => { },
        complete: () => {
          this.loading = false;
        }
      }
    );
  }

}
