import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ProductRequest } from '../../models/requests/product.request';
import { environment } from "../../../../environments/environment";
import { ProductResponse } from '../../models/responses/product.response';
@Injectable()
export class ProductsService {
  private readonly URL_API = environment.URL_API;
  private http = inject(HttpClient)
  public encoder: HttpParameterCodec;

  getProducts(query?: string,
              name?: string,
              barCode?: string,
              amount?: number,
              brandId?: number,
              modelId?: number,
              typeId?: number,
              unitTypeId?: number,
              branchId?: number,
              dateFrom?: string,
              dateTo?: string,
              dueDate?: string,
              isExpired?: boolean,
              page?: number,
              pageSize?: number,
              order?: string) {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });

    if (name !== undefined && name !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>name, 'name');
    }
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>query, 'query');
    }
    if (barCode !== undefined && barCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>barCode, 'barCode');
    }
    if (amount !== undefined && amount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>amount, 'amount');
    }
    if (brandId !== undefined && brandId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>brandId, 'brandId');
    }
    if (modelId !== undefined && modelId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>modelId, 'modelId');
    }
    if (typeId !== undefined && typeId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>typeId, 'typeId');
    }
    if (unitTypeId !== undefined && unitTypeId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>unitTypeId, 'unitTypeId');
    }
    if (branchId !== undefined && branchId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>branchId, 'branchId');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'dateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'dateTo');
    }
    if (dueDate !== undefined && dueDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dueDate, 'dueDate');
    }
    if (isExpired !== undefined && isExpired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isExpired, 'isExpired');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>page, 'Page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }
    if (order !== undefined && order !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>order, 'Order');
    }
    return this.http.get(`${this.URL_API}product`, { params: localVarQueryParameters });
  }

  getProduct(id: number, branchIds: number[]) {
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (branchIds) {
      branchIds.forEach((element) => {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>element, 'branchIds');
      })
    }
    return this.http.get(`${this.URL_API}product/${id}`, { params: localVarQueryParameters });
  }


  addProduct(product: ProductRequest) {
    return this.http.post<ProductResponse>(`${this.URL_API}product`, product);
  }

  editProduct(product: ProductRequest, id: number, branchIds: number[]) {
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (branchIds) {
      branchIds.forEach((element) => {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>element, 'branchIds');
      })
    }
    return this.http.put(`${this.URL_API}product/${id}`, product, { params: localVarQueryParameters });
  }


  deleteProduct(id: number, branchIds: number[]) {
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (branchIds) {
      branchIds.forEach((element) => {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>element, 'branchIds');
      })
    }
    return this.http.delete(`${this.URL_API}product/${id}`, { params: localVarQueryParameters });
  }

  getReport(reportType: number, fromDate?: string, toDate?: string) {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });

    if (reportType !== undefined && reportType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>reportType, 'reportType');
    }
    if (fromDate !== undefined && fromDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>fromDate, 'fromDate');
    }
    if (toDate !== undefined && toDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>toDate, 'toDate');
    }
    return this.http.get(`${this.URL_API}report/product`, { params: localVarQueryParameters, observe: 'response', responseType: 'blob' });
  }


  updateStock(amount: number, isRemove: boolean, id: number, branchId: number) {
    return this.http.patch(`${this.URL_API}product/${id}/addOrRemoveStock?amount=${amount}&isRemove=${isRemove}&branchId=${branchId}`, null);
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
}
