import { Component, OnInit, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { StorageService } from '../shared/services/storage.service';
import { RolesService } from '../main/services/roles/roles.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: MenuItem[] = [];
  private storageService = inject(StorageService);
  private rolesService = inject(RolesService);

  constructor(public layoutService: LayoutService) { }

  ngOnInit() {
    this.model = [
      {
        label: 'Inicio',
        icon: 'fa-duotone fa-house icon-primary',
        routerLink: ['/s']
      }
    ];

    const permissions = this.storageService.getPermissions();
    if (permissions) {
      permissions.forEach(element => {
        if (element == 'Acceso Total') {
          this.model.push({
            label: 'Productos',
            icon: 'fa-duotone fa-boxes-stacked icon-primary',
            routerLink: ['/s/products']
          });
          this.model.push({
            label: 'Tipo de productos',
            icon: 'fa-duotone fa-list icon-primary',
            routerLink: ['/s/products/types']
          });
          this.model.push({
            label: 'Usuarios',
            icon: 'fa-duotone fa-users icon-primary',
            routerLink: ['/s/users']
          });
          this.model.push({
            label: 'Marcas',
            icon: 'fa-duotone fa-copyright icon-primary',
            routerLink: ['/s/brands']
          });
        } else if (element == 'Ver Productos') {
          this.model.push({
            label: 'Productos',
            icon: 'fa-duotone fa-boxes-stacked icon-primary',
            routerLink: ['/s/products']
          });
        } else if (element == 'Ver Tipos de Producto') {
          this.model.push({
            label: 'Tipo de productos',
            icon: 'fa-duotone fa-list icon-primary',
            routerLink: ['/s/products/types']
          });
        } else if (element == 'Ver Usuarios') {
          this.model.push({
            label: 'Usuarios',
            icon: 'fa-duotone fa-users icon-primary',
            routerLink: ['/s/users']
          });
        } else if (element == 'Ver Marcas') {
          this.model.push({
            label: 'Marcas',
            icon: 'fa-duotone fa-copyright icon-primary',
            routerLink: ['/s/brands']
          });
        }
      });
    }
  }
}
