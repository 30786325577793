import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from "../../../../environments/environment";

@Injectable()
export class EnterpriseService {

  private readonly URL_API = environment.URL_API;
  private http = inject(HttpClient)

  getEnterprise() {
    return this.http.get(`${this.URL_API}enterprise`);
  }

  getBranches() {
    return this.http.get(`${this.URL_API}enterprise/branches`);
  }
}
