import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  let storageService = inject(StorageService);
  let router = inject(Router);
  let token = storageService.hasToken();
  if (token) {
    router.navigate(['/s']);
    return false;
  } else {
    return true;
  }
};
